import React from 'react';

import { Layer, Source } from 'react-map-gl';


const LegacyRoad = ({ road }) => {
  const sourceId = `road-source-${road.id}`;
  const source = {
    id: sourceId,
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: road.coordinates,
      }
    }
  }
  const layer = {
    id: `road-layer-${road.id}`,
    type: 'line',
    source: sourceId,
    beforeId: 'road-label',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ff0000',
      'line-opacity': 0.7,
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        5,
        1.5,
        10,
        2,
        18,
        30,
        22,
        200,
      ],
    },
  };

  return (
    <Source key={road.id} {...source} >
      <Layer {...layer} />
    </Source>
  )
}

export default LegacyRoad;
