import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { Map, Marker } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCrosshairs,
  faLocationArrow,
  faLocationCrosshairs,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap';
import LegacyRoad from '../LegacyRoad';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const Footer = styled.div`
  color: #555;
  background: linear-gradient(#ffffffee, #eeeeeeff);
  border-top: solid 1px #fff;
  box-shadow: 0 0 17px #555;
  touch-action: pan-x pan-y;
`

function App() {
  const mapRef = useRef();
  const [zoomInOnLocate, setZoomInOnLocate] = useState(true);
  const [horizontalScale, setHorizontalScale] = useState(null);
  const GEO_NOT_SUPPORTED = 'NOT_SUPPORTED';
  const GEO_UNAVAILABLE = 'UNAVAILABLE';
  const GEO_PENDING = 'PENDING';
  const GEO_INACTIVE = 'INACTIVE';
  const GEO_ACTIVE = 'ACTIVE';
  const [legacyRoads, setLegacyRoads] = useState(null);

  const geoLocateOptions = {
    enableHighAccuracy: true,
    maximumAge: 1000,
    timeout: 10000,
  };
  const initialViewState = {
    latitude: 40,
    longitude: -45,
    zoom: 0.8
  }
  const [followMe, setFollowMe] = useState(true);
  const [geoLocateStatus, setGeoLocateStatus] = useState(GEO_INACTIVE)
  const [myPosition, setMyPosition] = useState(null);
  const [positionWatcher, setPositionWatcher] = useState(null);

  useEffect(() => {
    if (followMe === true && myPosition) {
      mapRef.current.easeTo({
        center: [ myPosition.longitude, myPosition.latitude ],
        duration: 500,
        zoom: zoomInOnLocate && mapRef.current.getZoom() < 9 ? 9 : mapRef.current.getZoom(),
      });
      zoomInOnLocate && setTimeout(function () {
        setZoomInOnLocate(false)
      }, 2000);
    }
  }, [
    followMe,
    myPosition,
    zoomInOnLocate,
  ])

  const locateMe = () => {
    setFollowMe(true);
    setGeoLocateStatus(GEO_PENDING);
    if (!navigator.geolocation) {
      setGeoLocateStatus(GEO_UNAVAILABLE);
    } else {
      setPositionWatcher(navigator.geolocation.watchPosition(geoLocateSuccess, geoLocateFailure, geoLocateOptions));
    }
  }

  const geoLocateSuccess = (position) => {
    setGeoLocateStatus(GEO_ACTIVE);
    const { heading, latitude, longitude } = position.coords;
    const h = heading ? heading : myPosition ? myPosition.heading : null;
    setMyPosition({ heading: h, latitude, longitude })
  }

  const geoLocateFailure = (error) => {
    setGeoLocateStatus(GEO_INACTIVE);
    console.log(error);
  }

  const onMapLoad = () => {
    mapRef.current.on('touchmove', () => {
      setFollowMe(false);
    });
    mapRef.current.on('drag', () => {
      setFollowMe(false);
    });
    mapRef.current.on('zoom', (e) => {
      const bounds = mapRef.current.getBounds();
      const degreesLatitude = bounds.getNorth() - bounds.getSouth();
      const width = Math.max(document.documentElement.clientWidth, window.innerWidth);
      const height = Math.max(document.documentElement.clientHeight, window.innerHeight);
      const ratio = width/height;
      setHorizontalScale(111*degreesLatitude * ratio);
    });
    axios.get('https://api.legacy.theturn.io/regions/appalachia/routes')
      .then(response => {
        setLegacyRoads(response.data);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const zoomIn = () => {
    mapRef.current.zoomIn();
  }

  const zoomOut = () => {
    mapRef.current.zoomOut();
  }

  return (
    <>
      <Map
        dragRotate={false}
        initialViewState={initialViewState}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
        maxZoom={16.5}
        onLoad={onMapLoad}
        ref={mapRef}
        reuseMaps
        style={{width: '100vw', height: '100vh'}}
      >
        {myPosition && (
          <Marker latitude={myPosition.latitude} longitude={myPosition.longitude} anchor='center'>
            <FontAwesomeIcon
              icon={myPosition.heading ? faLocationArrow : faLocationCrosshairs}
              style={{ transform: `rotate(${myPosition.heading - 45}deg)` }}
              className='text-danger fs-5 shadow-lg'
            />
          </Marker>
        )}
        {mapRef && legacyRoads && legacyRoads.map(road => (
          <LegacyRoad key={road.id} road={road} />
        ))}
      </Map>
      <Footer className='fixed-bottom'>
        <Row className='text-center'>
          <Col>
            <FontAwesomeIcon
              onClick={zoomOut}
              icon={faMinus}
              className='cursor-pointer p-3'
              style={{ fontSize: '2.5rem' }}
            />
          </Col>
          <Col>
            <FontAwesomeIcon
              icon={geoLocateStatus === GEO_ACTIVE ? faLocationCrosshairs : faCrosshairs}
              onClick={() => locateMe()}
              className='cursor-pointer p-3'
              style={{ fontSize: '2.5rem' }}
            />
          </Col>
          <Col>
            <FontAwesomeIcon
              onClick={zoomIn}
              icon={faPlus}
              className='cursor-pointer p-3'
              style={{ fontSize: '2.5rem' }}
            />
          </Col>
        </Row>
        {horizontalScale && (
          <div
            className='position-absolute top-0 text-center w-100'
            style={{ marginTop: '-0.9rem', zIndex: -1 }}
          >
            <span className='bg-white p-2 pt-1 rounded'>
              { Math.round(horizontalScale) }km
            </span>
          </div>
        )}
      </Footer>
    </>
  );
}

export default App;
